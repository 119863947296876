import React from 'react';
import { Link, PageProps } from 'gatsby';
import BareLayout from '@/components/BareLayout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const AcceptableUsePolicy: React.FC<PageProps> = () => (
   <BareLayout pageTitle="Acceptable Use Policy">
      <div className="container legal-info">
         <h1>Acceptable Use Policy</h1>

         <p>This Acceptable Use Policy (<strong>“Policy”</strong>) is entered into by and between you and Lingco Language Labs, Inc., a Delaware corporation, whose principal place of business is 1146 South Washington Square, Lansing, Michigan 48910 (“Lingco”). Lingco provides a software platform (the <strong>“Software”</strong>) which consists of technology hosted on Lingco’s computers and accessed remotely, via the cloud. The following terms restrict your use of the Software and our website, <a href="https://lingco.io/">https://lingco.io/</a> (<strong>“Website”</strong>), whether as a guest or a registered user.</p>
         <p>Please read this Policy carefully before you start to use the Website or Software. If you do not want to agree to this Policy, you must not access or use the Software or Website.</p>
         <p>This Website is offered and available to users who are 13 years of age or older and meet other eligibility requirements set by Lingco. By using this Website or the Software, you represent and warrant that you are of legal age to form a binding contract with Lingco and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website or Software.</p>
         <p>Therefore, in consideration for the commitments set forth below, the adequacy of which consideration the parties hereby acknowledge, the parties agree as follows.</p>
         <p>YOU ACKNOWLEDGE THAT YOU HAVE READ THIS POLICY, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS.</p>

         <h2>1.  YOUR RESPONSIBILITIES &amp; RESTRICTIONS.</h2>
         <h3>1.1.	Acceptable Use.</h3>
         <p>You will comply with this Policy. You will not: (a) use the Software for service bureau or time-sharing purposes or in any other way allow third parties to exploit the Software; (b) provide Software passwords or other log-in information to any third party; (c) share non-public Software features or content with any third party; (d) access the Software in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the Software, or to copy any ideas, features, functions or graphics of the Software; or (e) engage in web scraping or data scraping on or related to the Software, including without limitation collection of information through any software that simulates human activity or any bot or web crawler. In the event that it suspects any breach of the requirements of this Section 1.1, including without limitation by Users, Lingco may suspend your access to the Software without advanced notice, in addition to such other remedies as Lingco may have. This Policy does not require that Lingco take any action against you or any user or other third party for violating this Section 1.1, or this Policy, but Lingco is free to take any such action it sees fit. The Prohibited Uses described in Section 4 below will supplement any restrictions contained in this Section 1.</p>

         <h3>1.2.	Unauthorized Access.</h3>
         <p>You will take reasonable steps to prevent unauthorized access to the Software, including without limitation by protecting its passwords and other log-in information. You will notify Lingco immediately of any known or suspected unauthorized use of the Software or breach of its security and will use best efforts to stop said breach.</p>

         <h3>1.3.	Compliance with Laws.</h3>
         <p>In its use of the Software, you will comply with all applicable laws, including without limitation laws governing the protection of personally identifiable information and other laws applicable to the protection of your Data.</p>

         <h3>1.4.	Users &amp; Software Access.</h3>
         <p>You are responsible and liable for: (a) your use of the Software, including without limitation unauthorized user conduct and any user conduct that would violate this Policy of the terms of service applicable to you; and (b) any use of the Software through your account, whether authorized or unauthorized.</p>

         <h2>2.  Changes to this Policy.</h2>
         <p>Lingco may revise and update this Policy from time to time in its sole discretion. All changes are effective immediately when Lingco posts them, and apply to all access to and use of the Website and Software after the effective date. Your continued use of the Website or Service following the posting of revised Policy means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>

         <h2>3. Access and Account Security.</h2>
         <p>We reserve the right to withdraw or amend the Website or Software, and any service or material we provide on the Website or Software, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website or Software is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website or Software, or the entire Website or Software, to users, including registered users.</p>

         <p>You are responsible for both:</p>
         <ul>
            <li>Making all arrangements necessary for you to have access to the Website or Software.</li>
            <li>Ensuring that all persons who access the Website or Software through your internet connection are aware of this Policy and comply with them.</li>
         </ul>

         <p>To access the Website or Software or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website or Software that all the information you provide on the Website or Software is correct, current, and complete. You agree that all information you provide to register with this Website or Software or otherwise, including, but not limited to, through the use of any interactive features on the Website or Software, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>

         <p>If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or Software or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>

         <p>We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of this Policy.</p>

         <h2>4.  Prohibited Uses.</h2>
         <p>You may use the Website or Software only for lawful purposes and in accordance with this Policy. You agree not to use the Website or Software:</p>
         <ul>
            <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
            <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the content standards set out in this Policy or as separately described by Lingco.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
            <li>To impersonate or attempt to impersonate Lingco, a Lingco employee, another user, or any other person or entity, including by using email addresses or screen names.</li>
            <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website or Software, or which, as determined by us, may harm Lingco or users of the Website or Software, or expose them to liability.</li>
         </ul>

         <p>Additionally, you agree not to:</p>
         <ul>
            <li>Use the Website or Software in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website or Software, including their ability to engage in real time activities through the Website or Software.</li>
            <li>Use any robot, spider, or other automatic device, process, or means to access the Website or Software for any purpose, including monitoring or copying any of the material on the Website or Software.</li>
            <li>Use any manual process to monitor or copy any of the material on the Website or Software, or for any other purpose not expressly authorized in this Policy, without our prior written consent.</li>
            <li>Use any device, software, or routine that interferes with the proper working of the Website or Software.</li>
            <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
            <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website or Software, the server on which the Website or Software is stored, or any server, computer, or database connected to the Website or Software. </li>
            <li>Attack the Website or Software via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Otherwise attempt to interfere with the proper working of the Website or Software.</li>
         </ul>

         <h2>5. Information About You and Your Visits to the Website or Software.</h2>
         <p>All information we collect on the Website or Software is subject to our Privacy Policy.  By using the Website or Software, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

         <h2>Revision</h2>
         <p>Posted/Revised: 2020 November</p>
      </div>
   </BareLayout>
);

export default AcceptableUsePolicy;
