import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from './Footer';
import GetInTouch from './GetInTouch';
import Header from './Header';
import '../../style/global.scss';

interface ILayoutProps {
   pageTitle: string;
}

const BareLayout: React.FC<ILayoutProps> = ({children, pageTitle}) => {
   return (
      <div className="sitewrapper">
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>
         {children}
      </div>
   );
};

export default BareLayout;